<template>
    <logo></logo>
    <content-bg>
        <template v-slot:content>
            <h4 class="title" v-if="pro && pro.length">俱乐部</h4>
            <div class="menuList">
                <div v-if="pro && pro.length"
                     :key="item.id"
                     v-for="(item,index) in pro"
                     class="menuItem" @click="menuClick(item)">
                    <index-number>
                        <template v-slot:number>{{ index + 1 }}</template>
                    </index-number>
                    <menu-card>
                        <template v-slot:icon><img class="icon" :src="item.logo || require('@i/firstMenu/xunlian.svg')"
                                                   alt=""></template>
                        <template v-slot:label>
                            <p class="label YouSheBiaoTiHei"
                               :class="item.name.length>6 ? 'small' : ''">{{ item.name }}</p>
                        </template>
                    </menu-card>
                </div>
            </div>
            <h4 class="title" v-if="basic && basic.length">基地</h4>
            <div class="menuList">
                <div v-if="basic && basic.length"
                     :key="item.id"
                     v-for="(item,index) in basic"
                     class="menuItem" @click="menuClick(item)">
                    <index-number>
                        <template v-slot:number>{{ pro.length + index + 1 }}</template>
                    </index-number>
                    <menu-card>
                        <template v-slot:icon><img class="icon" :src="item.logo || require('@i/firstMenu/xunlian.svg')"
                                                   alt=""></template>
                        <template v-slot:label>
                            <p class="label YouSheBiaoTiHei"
                               :class="item.name.length>6 ? 'small' : ''">{{ item.name }}</p>
                        </template>
                    </menu-card>
                </div>
            </div>
        </template>
    </content-bg>
    <pagination-com :current-page="showData.page" :total-page="showData.totalPage"
                    @changePage="changePage"></pagination-com>
    <img class="exit" @click="exit" src="@i/common/quit.svg" alt="">
</template>

<script>
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, toRefs, onMounted, watch} from "vue";
import {useStore} from "vuex";
import baseCof from "../../config";

export default {
    name: "organization",
    setup() {

        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            menuList: [],

            // 显示的菜单，分页
            page: {
                pageNum: 1,
                pageSize: 10
            },
            showData: {},

            pro: [],
            basic: []
        });


        const getTenantList = () => {
            proxy.$server.getTenantList({id: proxy.$Config.PARENT_ID}).then((res) => {
                let tenantList = [];

                res.data.childTenants.map(item => {
                    tenantList.push({
                        ...item,
                        router: {
                            name: 'talent',
                            path: '/talent/index'
                        }
                        // router: {
                        //     name: 'team',
                        //     path: '/team/index'
                        // }
                    })
                })

                state.menuList = [...tenantList];
                state.showData = proxy.$utils.paginationFn(state.menuList, state.page.pageNum, state.page.pageSize);
            })
        }

        watch(() => store.state.identifyData, (val, old) => {
            console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                ...state.showData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: menuClick
            })
        })

        watch(() => state.showData, (val, old) => {
            // console.log(val, old);

            state.pro = [];
            state.basic = [];

            state.showData.showList.map(item => {
                if (item.type === 'PRO') {
                    state.pro.push(item)
                } else if (item.type === 'BASIC') {
                    state.basic.push(item)
                }
            })

            console.log(state.basic)
        })

        onMounted(() => {
            getTenantList();
        })

        const changePage = (page) => {
            state.page.pageNum = page;
            state.showData = proxy.$utils.paginationFn(state.menuList, state.page.pageNum, state.page.pageSize);
        }

        const menuClick = (item) => {
            store.commit('setTenantData', item)
            router.push({
                ...item.router
            })
        }

        const exit = () => {
            store.dispatch('LogOut').then(res => {
                if (res === 'success') {
                    router.push({
                        path: '/',
                        name: 'home'
                    })
                }
            })
        }


        return {
            ...toRefs(state),
            changePage,
            menuClick,
            exit
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .contentBg {
    top: 0 !important;
}

::v-deep .menuItem {
    width: 240px;
    height: 245px;
    margin-right: 18px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 36px;
    text-align: center;

    &:nth-child(5n+1) {
        margin-left: 43px;
    }

    .cardItem {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .icon {
        width: 130px;
        height: 130px;
        margin-top: 30px;
        margin-bottom: 16px;
    }

    .label {
        font-weight: 400;
        font-size: 28px;
        line-height: 39px;
        padding-left: 20px;
        padding-right: 20px;

        &.small {
            font-size: 23px;
            line-height: 27px;
        }
    }
}

::v-deep.indexNum {
    left: 16px;
    top: 16px;
}

::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}

.title {
    font-weight: 500;
    font-size: 32px;
    line-height: 45px;
    color: rgba(255, 255, 255, .8);
    padding-left: 45px;
    padding-bottom: 16px;
    padding-top: 35px;
}

.menuList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

}

.exit {
    position: absolute;
    top: 30px;
    right: 50px;
    width: 50px;
}
</style>